// Theme

const theme = {
  font: {
    primary: `'GaramondPremrPro'`,
    normal: `'GaramondPremrPro'`,
    medium: `'GaramondPremrPro'`,
    bold: `'GaramondPremrPro Bold'`,
    extrabold: `'GaramondPremrPro Extra Bold'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px;",
  },

  color: {
    primary: "#3b1d1d",
    secondary: "#89494a",
    accent: "#c5aca8",
    background: {
      white: "#ffffff",
      light: "#FFF1F1",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#FFF1F1",
      dark: "#FFF1F1",
      darker: "#89494a",
    },
    black: {
      lightest: "#89494a",
      light: "#c5aca8",
      regular: "#3b1d1d",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
